import { Button, Pressable, StyleSheet, Text, View } from 'react-native'
import colors from '../js/colors'

export default function AppBar({ username, onUsernamePressed, onLogoutPressed }: { username: string; onUsernamePressed: () => void; onLogoutPressed: () => void; }) {
  return (
    <View style={styles.appBar}>
      <Pressable onPress={onUsernamePressed}>
        <Text style={styles.appBarText}>Welcome back, {username}!</Text>
      </Pressable>
      <Button
        title='Logout'
        onPress={onLogoutPressed}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  appBar: {
    backgroundColor: colors.backgroundMuted,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: colors.backgroundStrong,
    zIndex: 1
  },
  appBarText: {
    color: colors.foreground,
    fontSize: 16,
    fontWeight: 'bold'
  }
})