import * as auth from './auth'
import supabase from './supabase'

export async function getUsername({ playerId }: { playerId?: string }) {
  const userId = playerId ?? (await auth.getUser()).id
  return supabase
    .from('player')
    .select('username')
    .eq('id', userId)
    .single()
    .then(res => {
      if (res.error) throw res.error
      else return res.data.username
    })
}

export async function postUsername({ username }: { username: string }) {
  const user = await auth.getUser()
  return supabase
    .from('player')
    .insert({ username })
    .eq('id', user.id)
    .then(res => {
      if (res.error) throw res.error
    })
}

export async function putUsername({ username }: { username: string }) {
  const user = await auth.getUser()
  return supabase
    .from('player')
    .update({ username })
    .eq('id', user.id)
    .then(res => {
      if (res.error) throw res.error
    })
}