type Palette = {
  background: string;
  backgroundMuted: string;
  backgroundStrong: string;
  foreground: string;
  foregroundMuted: string;
  foregroundStrong: string;
  foregroundHighlighted: string;
  tileBackground: string;
  tileBackgroundHighlight: string;
  tileOutline: string;
  tokenBackground: string;
  tokenEmpty: string;
  tokenHost: string;
  tokenGuest: string;
  tokenOutline: string;
  tokenOutlineHighlighted: string;
}

import { Appearance } from 'react-native'

const colors: Record<'light' | 'dark', Palette> = {
  light: {
    background: '#C0DFA1',
    backgroundMuted: '#E0F0D1',
    backgroundStrong: '#9FC490',
    foreground: '#1D2E28',
    foregroundMuted: '#18392B',
    foregroundStrong: '#212224',
    foregroundHighlighted: '#c80',
    tileBackground: '#CBCFCB',
    tileBackgroundHighlight: '#E5E386',
    tileOutline: '#466346',
    tokenBackground: '#F1F0DD',
    tokenEmpty: '#0000',
    tokenHost: '#074587',
    tokenGuest: '#9F1E36',
    tokenOutline: '#466346',
    tokenOutlineHighlighted: '#E5E386'
  },
  dark: {
    background: '#303230',
    backgroundMuted: '#283830',
    backgroundStrong: '#202422',
    foreground: '#D8E0D0',
    foregroundMuted: '#88A080',
    foregroundStrong: '#FFF',
    foregroundHighlighted: '#FF4',
    tileBackground: '#3C423C',
    tileBackgroundHighlight: '#74A355',
    tileOutline: '#8C918C',
    tokenBackground: '#202020',
    tokenEmpty: '#0000',
    tokenHost: '#4080D8',
    tokenGuest: '#E03858',
    tokenOutline: '#8C918C',
    tokenOutlineHighlighted: '#74A355'
  }
}

export default colors[Appearance.getColorScheme()]