import { createClient } from '@supabase/supabase-js'
import { setupURLPolyfill } from 'react-native-url-polyfill'
import { storage } from '../storage'
import { Database } from './types'

if (process.env.PLATFORM !== 'web') setupURLPolyfill()

const url = process.env.EXPO_PUBLIC_SUPABASE_URL
const key = process.env.EXPO_PUBLIC_SUPABASE_KEY

// https://supabase.com/docs/reference/javascript/initializing
const supabase = createClient<Database>(
  url,
  key,
  {
    auth: {
      storage
    }
  }
)

export default supabase