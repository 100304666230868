import { getUsername } from './player'
import supabase from './supabase'
import { UserWithOptionalUsername, UserWithUsername } from './types'

export async function signup({ email, password }) {
  return supabase
    .auth
    .signUp({ email, password })
    .then(res => {
      if (res.error) throw res.error
      else return res.data
    })
}

export async function login({ email, password }: { email: string; password: string; }) {
  return supabase
    .auth
    .signInWithPassword({ email, password })
    .then(res => {
      if (res.error) throw res.error
      else return res.data
    })
}

export async function logout() {
  return supabase
    .auth
    .signOut()
    .then(res => {
      if (res.error) throw res.error
    })
}

export async function refresh() {
  return supabase
    .auth
    .refreshSession()
    .then(res => {
      if (res.error) throw res.error
      else return res.data
    })
}

export async function getUser() {
  return supabase
    .auth
    .getSession()
    .then(res => {
      if (res.error) throw res.error
      else if (!res.data.session) throw new Error('Session not cached')
      else return res.data.session.user
    })
}

export async function getUserWithOptionalUsername(): Promise<UserWithOptionalUsername> {
  return getUser()
    .then(async user => {
      return getUsername({ playerId: user.id })
        .then(username => ({
          ...user,
          username
        }))
        .catch(() => ({
          ...user,
          username: undefined
        }))
    })
}

export async function getUserWithUsername(): Promise<UserWithUsername> {
  return getUserWithOptionalUsername().then(user => {
    if (user.username === undefined) throw new Error('Username undefined')
    return user as UserWithUsername
  })
}