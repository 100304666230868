import { NavigationProp } from '@react-navigation/native'
import { useEffect, useMemo, useState } from 'react'
import { Alert, Button, StyleSheet, Text, TextStyle, View } from 'react-native'
import Board from '../components/Board'
import colors from '../js/colors'
import { END_COORDS, GameState, getInitialState, pass, tokensOnTile } from '../js/game'
import { getGame, getUserWithUsername, subscribeToGameUpdates, unsubscribeFromGameUpdates, updateGame } from '../js/supabase'
import { GameWithPlayers, UserWithUsername } from '../js/supabase/types'

export default function Game({ gameId, navigation }: { navigation: NavigationProp<any>; gameId: string; }) {
  const [user, setUser] = useState<UserWithUsername>()

  const [game, setGame] = useState<GameWithPlayers | undefined>()

  const [updateCount, setUpdateCount] = useState(0)

  const gameState: GameState | undefined = useMemo(
    () => {
      if (game) {
        if (game.state) {
          try {
            return JSON.parse(game.state)
          } catch (err) {
            Alert.alert('Game Parse Error', 'Error parsing game state')
            console.error('🕹️', err)
          }
        } else {
          console.log('🕹️', 'Initializing new game')
          return getInitialState()
        }
      }
      else return undefined
    },
    [game]
  )

  const currentPlayerName = useMemo(
    () => {
      if (!gameState) return undefined
      else return gameState.currentPlayer === 'host'
        ? game.host_name
        : game.guest_name
    },
    [gameState]
  )

  const currentRoll = useMemo(
    () => {
      if (!gameState) return undefined
      else return gameState.currentRoll
    },
    [gameState]
  )

  function onMove(newState: GameState) {
    // update game state
    const state = JSON.stringify(newState)
    const updatedGame = {
      ...game,
      state
    }
    // host won
    const hostEndTile = newState.board[END_COORDS.host.y][END_COORDS.host.x]
    if (tokensOnTile(hostEndTile).count === 7) updatedGame.winner = game.host
    // guest won
    const guestEndTile = newState.board[END_COORDS.guest.y][END_COORDS.guest.x]
    if (tokensOnTile(guestEndTile).count === 7) updatedGame.winner = game.guest
    // save last state
    const lastGame = game
    // perform optimistic update
    setGame(updatedGame)
    // requeust update
    updateGame({
      gameId: game.id,
      state,
      winner: updatedGame.winner
    })
      .then(newGame => {
        console.log('🕹️', 'Game state saved')
        setGame({
          ...newGame,
          host_name: game.host_name,
          guest_name: game.guest_name
        })
      })
      .catch(err => {
        Alert.alert('Fetch Game Error', 'Error retrieving game game')
        console.error('🕹️', err)
        // undo optimistic updates
        setGame(lastGame)
        setUpdateCount(n => n - 1)
      })
    setUpdateCount(n => n + 1)
  }

  function onPassPressed() {
    if (currentPlayerName === user.username) onMove(pass(gameState))
  }

  useEffect(() => {
    if (!user) return
    // get last saved games tate
    getGame({ gameId })
      .then(setGame)
      .then(() => {
        // listen for changes to the game state (opponent made a move)
        subscribeToGameUpdates({
          onUpdate: newGame => setGame(lastGame => ({
            ...newGame,
            host_name: lastGame.host_name,
            guest_name: lastGame.guest_name
          }))
        })
      })
      .catch(err => {
        Alert.alert('Fetch Game Error', 'Error retrieving game game')
        console.error('🕹️', err)
        if (navigation.canGoBack()) navigation.goBack()
        else navigation.navigate('Home')
      })
    // cleanup function
    return () => {
      unsubscribeFromGameUpdates()
    }
  }, [user])

  navigation.addListener('focus', () => {
    setUser(undefined)
    getUserWithUsername()
      .then(setUser)
      .catch(err => {
        console.error('🕹️', err)
        navigation.navigate('Login')
      })
  })

  function renderPlayerText() {
    let style: TextStyle = { color: gameState.currentPlayer === 'host' ? colors.tokenHost : colors.tokenGuest }
    if (currentPlayerName === user.username) style = { ...style, fontWeight: 'bold' }

    return (
      <Text style={styles.playerText}>
        <Text style={style}>
          {currentPlayerName === user.username ? 'You': currentPlayerName}
        </Text>
        <Text> rolled a {currentRoll}</Text>
      </Text>
    )
  }

  function renderWinnerText() {
    const winnerName = game.winner === game.host
      ? game.host_name
      : game.guest_name

    return (
      <Text style={styles.winnerText}>{winnerName} won!</Text>
    )
  }

  function renderText() {
    if (game.winner) return renderWinnerText()
    else return renderPlayerText()
  }

  if (game && gameState && user) return (
    <View style={styles.container}>
      { renderText() }
      <View style={styles.boardContainer}>
        <Board
          key={updateCount}
          userName={user.username}
          hostName={game.host_name}
          guestName={game.guest_name}
          state={gameState}
          setState={onMove}
          isGameOver={Boolean(game.winner)}
        />
        <Button
          title='Pass'
          onPress={onPassPressed}
          disabled={Boolean(game.winner) || (currentPlayerName !== user.username)}
        />
        <Text style={{ ...styles.hintText, ...styles.startHintText, ...styles.leftHintText }}>Start</Text>
        <Text style={{ ...styles.hintText, ...styles.startHintText, ...styles.rightHintText }}>Start</Text>
        <Text style={{ ...styles.hintText, ...styles.endHintText, ...styles.leftHintText }}>End</Text>
        <Text style={{ ...styles.hintText, ...styles.endHintText, ...styles.rightHintText }}>End</Text>
        <Button
          title='Return Home'
          onPress={() => navigation.goBack()}
        />
      </View>
    </View>
  )
  else return (
    <View style={styles.container}>
      <Text style={styles.textMuted}>Loading...</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    padding: 20
  },
  boardContainer: {
    gap: 10,
    position: 'relative'
  },
  textMuted: {
    color: colors.foregroundMuted
  },
  playerText: {
    color: colors.foreground,
    fontSize: 18
  },
  winnerText: {
    color: colors.foregroundStrong,
    fontWeight: 'bold',
    fontSize: 20
  },
  hintText: {
    position: 'absolute',
    color: colors.foregroundMuted,
  },
  startHintText: {
    top: 255
  },
  endHintText: {
    top: 315
  },
  leftHintText: {
    right: '100%',
    marginRight: 10,
  },
  rightHintText: {
    left: '100%',
    marginLeft: 10,
  }
})