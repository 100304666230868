import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'
import Game from './views/Game'
import Home from './views/Home'
import Login from './views/Login'
import Refresh from './views/Refresh'
import Signup from './views/Signup'
import Username from './views/Username'

const Stack = createNativeStackNavigator()

function documentTitleFormatter(options, route) {
  const page = `${options?.title ?? route?.name}`
  return `The Game of Ur${ page ? ` - ${ page }` : '' }`
}

export default function App() {
  return (
    <NavigationContainer documentTitle={{ formatter: documentTitleFormatter }}>
      <Stack.Navigator initialRouteName='Refresh' screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name='Refresh'
          component={Refresh}
        />
        <Stack.Screen name='Login'>
          {
            props => <Login
              navigation={props.navigation}
              email={props.route.params?.['email']}
              password={props.route.params?.['password']}
            />
          }
        </Stack.Screen>
        <Stack.Screen name='Signup'>
          {
            props => <Signup
              navigation={props.navigation}
              email={props.route.params?.['email']}
              password={props.route.params?.['password']}
            />
          }
        </Stack.Screen>
        <Stack.Screen
          name='Username'
          component={Username}
        />
        <Stack.Screen
          name='Home'
          component={Home}
        />
        <Stack.Screen name='Game'>
          {
            props => <Game
              navigation={props.navigation}
              gameId={props.route.params?.['gameId']}
            />
          }
        </Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  )
}