import { useState } from 'react'
import { Button, StyleSheet, TextInput, View } from 'react-native'
import colors from '../js/colors'

type HostGameFunction = () => void

type JoinGameFunction = (code: string) => void

export function NewGame({ hostGame, joinGame, canHost }: { hostGame: HostGameFunction; joinGame: JoinGameFunction; canHost: boolean; }) {
  const [joinCode, setJoinCode] = useState('')

  return (
    <>
      <Button
        title='Host New Game'
        onPress={hostGame}
        disabled={!canHost}
      />
      <View style={styles.joinGameContainer}>
        <TextInput
          value={joinCode}
          onChangeText={text => setJoinCode(text.toUpperCase().slice(0, 3))}
          style={styles.joinGameInput}
          placeholder='Game code'
          autoCapitalize='characters'
        />
        <Button
          title='Join Game'
          onPress={() => joinGame(joinCode)}
          disabled={joinCode.length === 0}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  joinGameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: 10
  },
  joinGameInput: {
    color: colors.foregroundStrong,
    backgroundColor: colors.backgroundMuted,
    paddingHorizontal: 10,
    borderRadius: 4,
    flex: 1
  }
})