/**
 * TODO: add synchronous storage
 * @see https://github.com/mrousavy/react-native-mmkv#expo
 */
import { MMKV } from 'react-native-mmkv'

/**
 * Class that conforms to the
 * [Storage](https://developer.mozilla.org/en-US/docs/Web/API/Storage)
 * interface, allowing for synchronous access to persistent data.
 */
export class Storage {
  storage: MMKV

  constructor() {
    this.storage = new MMKV()
  }

  key(n: number) {
    return this.storage.getAllKeys().at(n)
  }

  getItem(key: string) {
    return this.storage.getString(key)
  }

  get length() {
    return this.storage.getAllKeys().length
  }

  setItem(key: string, value: any) {
    this.storage.set(key, String(value))
  }

  removeItem(key: string) {
    this.storage.delete(key)
  }

  clear() {
    this.storage.clearAll()
  }
}

const storage = globalThis.localStorage
  ? global.localStorage
  : new Storage()

export { storage }