import { NavigationProp } from '@react-navigation/native'
import { useState } from 'react'
import { Alert, Button, StyleSheet, Text, TextInput, View } from 'react-native'
import colors from '../js/colors'
import { getUserWithOptionalUsername, postUsername, putUsername } from '../js/supabase'
import { UserWithOptionalUsername } from '../js/supabase/types'

export default function Home({ navigation }: { navigation: NavigationProp<any> }) {
  const [user, setUser] = useState<UserWithOptionalUsername>()

  const [newUsername, setNewUsername] = useState('')

  const trimmedUsername = newUsername.trim()

  function onSubmitPressed() {
    if (user.username) {
      if (trimmedUsername !== user.username) putUsername({ username: trimmedUsername })
          .then(() => {
            Alert.alert(`Username updated to "${trimmedUsername}"`)
            console.log('🕹️ username updated to', trimmedUsername)
            navigation.navigate('Home')
          })
          .catch(err => {
            Alert.alert('Username Error', 'Error updating username')
            console.error('🕹️', err)
          })
      else navigation.navigate('Home')
    }
    else postUsername({ username: trimmedUsername })
      .then(() => {
        Alert.alert(`Username set to "${trimmedUsername}"`)
        navigation.navigate('Home')
      })
      .catch(err => {
        Alert.alert('Username Error', 'Error creating username')
        console.error('🕹️', err)
      })
  }

  navigation.addListener('focus', () => {
    setUser(undefined)
    getUserWithOptionalUsername()
      .then(newUser => {
        setUser(newUser)
        setNewUsername(newUser.username ?? '')
      })
      .catch(err => {
        console.error('🕹️', err)
        navigation.navigate('Home')
      })
  })

  if (user) return (
    <View style={styles.centerContainer}>
      <View style={styles.flexContainer}>
        <Text style={styles.text}>Please enter a username:</Text>
        <Text style={styles.italicText}>({user.email})</Text>
        <TextInput
          style={styles.usernameInput}
          value={newUsername}
          onChangeText={setNewUsername}
          placeholder='Username'
          autoCapitalize='words'
        />
        <Button
          title='Submit'
          onPress={onSubmitPressed}
          disabled={trimmedUsername.length === 0}
        />
      </View>
    </View>
  )
  else return (
    <View style={styles.centerContainer}>
      <Text style={styles.text}>Loading...</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexContainer: {
    rowGap: 10
  },
  text: {
    color: colors.foreground,
    textAlign: 'center'
  },
  italicText: {
    color: colors.foreground,
    fontStyle: 'italic',
    textAlign: 'center'
  },
  usernameInput: {
    color: colors.foregroundStrong,
    backgroundColor: colors.backgroundMuted,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 4,
    textAlign: 'center'
  }
})