import { useEffect, useRef } from 'react'
import { Animated, Pressable, StyleSheet, Text } from 'react-native'
import colors from '../js/colors'
import { GameState } from '../js/game'
import { GameWithPlayers } from '../js/supabase/types'
import { getFormattedDatetime } from '../js/utils'

type GameListProps = {
  games: GameWithPlayers[] | undefined;
  userId: string;
  onGamePressed: (game: GameWithPlayers) => void;
}

export default function GameList({ games, userId, onGamePressed }: GameListProps) {
  if (games === undefined) return (
    <Text style={styles.textMuted}>
      Loading games...
    </Text>
  )

  else if (games.length === 0) return (
    <Text style={styles.textMuted}>
      No games
    </Text>
  )

  else {
    const opacity = useRef(new Animated.Value(0)).current

    useEffect(() => {
      Animated
        .timing(opacity, {
          toValue: 1,
          duration: 2000,
          useNativeDriver: process.env.PLATFORM !== 'web'
        })
        .start()
    }, [opacity])

    return games.map(game => {
      const gameInfo: React.JSX.Element[] = []
      const baseStyle = styles.textDefault

      // opponent
      const headerStyle = { ...baseStyle, ...styles.textHeader }
      if (game.host === userId) {
        const textStyle = game.guest ? headerStyle : { ...headerStyle, ...styles.textMuted }
        const opponentText = game.guest_name ?? 'No opponent'
        gameInfo.push(<Text style={textStyle} key={gameInfo.length+1}>{opponentText}</Text>)
      }
      else gameInfo.push(<Text style={headerStyle} key={gameInfo.length+1}>{game.host_name}</Text>)

      // date created
      const startDate = getFormattedDatetime(game.created_at)
      gameInfo.push(<Text style={baseStyle} key={gameInfo.length+1}>Created: {startDate}</Text>)

      // date updated/finished
      if (game.updated_at) {
        const updateDate = getFormattedDatetime(game.updated_at)
        const updateText = `${ game.winner ? 'Finished:' : 'Last move:' } ${ updateDate }`
        gameInfo.push(<Text style={baseStyle} key={gameInfo.length+1}>{updateText}</Text>)
      }

      // status
      if (game.winner) {
        const winner = game.winner === game.host ? game.host_name : game.guest_name
        gameInfo.push(<Text style={baseStyle} key={gameInfo.length+1}>{`Winner: ${winner}`}</Text>)
      } else if (game.guest) {
        let player = game.host_name
        let playerId = game.host
        if (game.state) {
          const state: GameState = JSON.parse(game.state)
          player = state.currentPlayer === 'host' ? game.host_name : game.guest_name
          playerId = player === game.host_name ? game.host : game.guest
        }
        let style = baseStyle
        let playerText = `${player}'s turn`
        if (playerId === userId) {
          style = { ...style, ...styles.textStrong, ...styles.textHighlighted }
          playerText = 'Your turn!'
        }
        gameInfo.push(<Text style={style} key={gameInfo.length+1}>{playerText}</Text>)
      } else {
        const textStyle = { ...baseStyle, ...styles.textMuted }
        const gameTextEl = <Text style={styles.textStrong}>{game.code}</Text>
        gameInfo.push(<Text style={textStyle} key={gameInfo.length+1}>Invite code: {gameTextEl}</Text>)
      }

      return (
        <Pressable onPress={() => onGamePressed(game)} key={`${game.id}-${game.guest}-${game.updated_at}`}>
          <Animated.View style={{ ...styles.gameContainer, opacity }}>
            { gameInfo }
          </Animated.View>
        </Pressable>
      )
    })
  }
}

const styles = StyleSheet.create({
  gameContainer: {
    backgroundColor: colors.backgroundMuted,
    paddingHorizontal: 10,
    paddingVertical: 5,
    gap: 5,
    borderRadius: 4
  },
  textHeader: {
    color: colors.foregroundStrong,
    fontSize: 16,
    fontWeight: 'bold'
  },
  textDefault: {
    color: colors.foreground
  },
  textStrong: {
    color: colors.foregroundStrong,
    fontWeight: 'bold'
  },
  textMuted: {
    color: colors.foregroundMuted
  },
  textHighlighted: {
    color: colors.foregroundHighlighted
  },
  textFinished: {
    fontStyle: 'italic'
  }
})