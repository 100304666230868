/**
 * Game tiles are represented as single characters.
 *
 * - `0` no players are present on this tile
 * - `A-G` number of player 1 (host) tokens on this tile
 * from 1 (`A`) to 7 (`G`)
 * - `a-g` number of player 2 (guest) tokens on this tile
 * from 1 (`a`) to 7 (`g`)
 */
type GameTile = '0' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g'

/**
 * The game board is represented as rows of columns.
 */
type GameBoard = [
  [GameTile, GameTile, GameTile], // row 0
  [GameTile, GameTile, GameTile], // row 1
  [GameTile, GameTile, GameTile], // row 2
  [GameTile, GameTile, GameTile], // row 3
  [GameTile, GameTile, GameTile], // row 4
  [GameTile, GameTile, GameTile], // row 5
  [GameTile, GameTile, GameTile], // row 6
  [GameTile, GameTile, GameTile], // row 7
]

/**
 * Coordinate of a tile on the board.
 */
export type GameBoardCoord = {
  /**
   * Column number.
   */
  x: 0 | 1 | 2;
  /**
   * Row number.
   */
  y: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

/**
 * String representation of which player is currently playing.
 */
export type CurrentPlayer = 'host' | 'guest'

/**
 * An object representing the state of a game board at a given point in time.
 */
export type GameState = {
  currentPlayer: CurrentPlayer;
  currentRoll: number;
  board: GameBoard;
}

export const START_COORDS: Record<CurrentPlayer, GameBoardCoord> = {
  'host': {
    x: 0,
    y: 4,
  },
  'guest': {
    x: 2,
    y: 4,
  },
}

export const END_COORDS: Record<CurrentPlayer, GameBoardCoord> = {
  'host': {
    x: 0,
    y: 5,
  },
  'guest': {
    x: 2,
    y: 5,
  },
}

export const REROLL_COORDS: GameBoardCoord[] = [
  {
    x: 0,
    y: 0,
  },
  {
    x: 2,
    y: 0,
  },
  {
    x: 1,
    y: 3,
  },
  {
    x: 0,
    y: 6,
  },
  {
    x: 2,
    y: 6,
  },
]

const PATHS: Record<CurrentPlayer, GameBoardCoord[]> = {
  'host': [
    {
      x: 0,
      y: 4,
    },
    {
      x: 0,
      y: 3,
    },
    {
      x: 0,
      y: 2,
    },
    {
      x: 0,
      y: 1,
    },
    {
      x: 0,
      y: 0,
    },
    {
      x: 1,
      y: 0,
    },
    {
      x: 1,
      y: 1,
    },
    {
      x: 1,
      y: 2,
    },
    {
      x: 1,
      y: 3,
    },
    {
      x: 1,
      y: 4,
    },
    {
      x: 1,
      y: 5,
    },
    {
      x: 1,
      y: 6,
    },
    {
      x: 1,
      y: 7,
    },
    {
      x: 0,
      y: 7,
    },
    {
      x: 0,
      y: 6,
    },
    {
      x: 0,
      y: 5,
    },
  ],
  'guest': [
    {
      x: 2,
      y: 4,
    },
    {
      x: 2,
      y: 3,
    },
    {
      x: 2,
      y: 2,
    },
    {
      x: 2,
      y: 1,
    },
    {
      x: 2,
      y: 0,
    },
    {
      x: 1,
      y: 0,
    },
    {
      x: 1,
      y: 1,
    },
    {
      x: 1,
      y: 2,
    },
    {
      x: 1,
      y: 3,
    },
    {
      x: 1,
      y: 4,
    },
    {
      x: 1,
      y: 5,
    },
    {
      x: 1,
      y: 6,
    },
    {
      x: 1,
      y: 7,
    },
    {
      x: 2,
      y: 7,
    },
    {
      x: 2,
      y: 6,
    },
    {
      x: 2,
      y: 5,
    },
  ]
}

/**
 * Returns `true` if coordinate is a reroll tile.
 */
export function isCoordReroll(coord: GameBoardCoord): boolean {
  return Boolean(
    Object
      .values(REROLL_COORDS)
      .find(other => sameCoord(coord, other))
  )
}

/**
 * Returns `true` if both coordinates point to the same tile.
 */
export function sameCoord(c1: GameBoardCoord, c2: GameBoardCoord): boolean {
  return c1.x === c2.x && c1.y === c2.y
}

/**
 * Returns a tile with the number of tokens *increased*.
 *
 * WARNING: does not validate result.
 */
function incrementTokens(player: CurrentPlayer, tile: GameTile): GameTile {
  if (tile === '0') return tokensToCharacter(1, player)
  // @ts-ignore
  else return String.fromCharCode(tile.charCodeAt(0) + 1)
}

/**
 * Returns a tile with the number of tokens *decreased*.
 *
 * WARNING: does not validate result.
 */
function decrementTokens(tile: GameTile): GameTile {
  if (tile === 'A' || tile === 'a') return '0'
  // @ts-ignore
  return String.fromCharCode(tile.charCodeAt(0) - 1)
}

/**
 * Returns the count of tokens on a tile, along with the player who owns the tokens.
 */
export function tokensOnTile(tile: GameTile): { count: number; player?: CurrentPlayer; } {
  if (tile === '0') return { count: 0 }
  else if (/[A-G]/.test(tile)) return {
    player: 'host',
    count: (tile.charCodeAt(0) - 'A'.charCodeAt(0)) + 1
  }
  else if (/[a-g]/.test(tile)) return {
    player: 'guest',
    count: (tile.charCodeAt(0) - 'a'.charCodeAt(0)) + 1
  }
  else throw new Error('Invalid tile')
}

/**
 * Returns `true` iff player has one or more tokens on the specified tile.
 *
 * WARNING: does not validate input.
 */
export function isPlayerOnTile(tile: GameTile, player: CurrentPlayer): boolean {
  return player === 'host'
    ? /[A-G]/.test(tile)
    : /[a-g]/.test(tile)
}

/**
 * Returns `true` if the tile is no player tokens are present.
 */
export function isTileEmpty(tile: GameTile): boolean {
  return tile === '0'
}

/**
 * Returns `true` if any player tokens are present.
 */
export function isTileOccupied(tile: GameTile): boolean {
  return !isTileEmpty(tile)
}

/**
 * Returns the distance a token must travel to get from one coordinate to another.
 */
export function getDistance(player: CurrentPlayer, origin: GameBoardCoord, target: GameBoardCoord): number {
  const path = PATHS[player]
  const startIndex = path.findIndex(coord => sameCoord(coord, origin))
  if (startIndex === -1) throw new Error('Origin not in player path')
  const endIndex = path.findIndex(coord => sameCoord(coord, target))
  if (endIndex === -1) throw new Error('Target not in player path')
  return endIndex - startIndex
}

/**
 * Converts a number of tokens to the character used for storing game state.
 */
function tokensToCharacter(tokens: number, player: CurrentPlayer): GameTile {
  if (tokens === 0) return '0'
  // @ts-ignore: I know what I'm doing just relax bro
  else if (player === 'host') return String.fromCharCode('A'.charCodeAt(0) + tokens - 1)
  // @ts-ignore: I know what I'm doing just relax bro
  else return String.fromCharCode('a'.charCodeAt(0) + tokens - 1)
}

/**
 * Returns a probability-weighted roll.
 */
function rollDice(): number {
  return Array(4)
    .fill(null)
    .map(() => Math.random() > 0.5)
    .reduce((prev, cur) => prev + (cur ? 1 : 0), 0)
}

/**
 * Returns the state of the board at the start of a game.
 */
function getInitialBoard(): GameBoard {
  return [
    ['0', '0', '0'],
    ['0', '0', '0'],
    ['0', '0', '0'],
    ['0', '0', '0'],
    [tokensToCharacter(7, 'host'), '0', tokensToCharacter(7, 'guest')],
    ['0', '0', '0'],
    ['0', '0', '0'],
    ['0', '0', '0'],
  ]
}

/**
 * Returns a game state before anyone has moved.
 */
export function getInitialState(): GameState {
  return {
    currentPlayer: 'host',
    currentRoll: rollDice(),
    board: getInitialBoard(),
  }
}

/**
 * Performs a move without any validation.
 */
function _move(player: CurrentPlayer, board: GameBoard, origin: GameBoardCoord, target: GameBoardCoord): GameBoard {
  // take token from origin
  board[origin.y][origin.x] = decrementTokens(board[origin.y][origin.x])
  // capture opponent token
  const opponent = player === 'host' ? 'guest' : 'host'
  if (isPlayerOnTile(board[target.y][target.x], opponent)) {
    board[target.y][target.x] = decrementTokens(board[target.y][target.x])
    board[START_COORDS[opponent].y][START_COORDS[opponent].x] = incrementTokens(opponent, board[START_COORDS[opponent].y][START_COORDS[opponent].x])
  }
  // add token to target
  board[target.y][target.x] = incrementTokens(player, board[target.y][target.x])
  // return copy of board
  return board
}

/**
 * Attempts to perform the specified move, throwing an error if the move is illegal.
 */
export function move(state: GameState, origin: GameBoardCoord, target: GameBoardCoord): GameState {
  // cannot move a tile that isn't there
  if (!isPlayerOnTile(state.board[origin.y][origin.x], state.currentPlayer)) throw new Error('Player has no token at origin')
  // unless player is moving to an end tile...
  else if (!sameCoord(target, END_COORDS[state.currentPlayer])) {
    // if the token is occupied...
    if (isTileOccupied(state.board[target.y][target.x])) {
      // cannot stack tokens
      if (isPlayerOnTile(state.board[target.y][target.x], state.currentPlayer)) throw new Error('Target is currently occupied')
      // cannot capture on reroll tiles
      if (REROLL_COORDS.some(coord => sameCoord(coord, target))) throw new Error('Cannot capture on reroll tile')
    }
  }
  // ensure that move is the correct length
  if (getDistance(state.currentPlayer, origin, target) !== state.currentRoll) throw new Error('Tile distance does not match roll')
  // make the move (mutate state object)
  _move(state.currentPlayer, state.board, origin, target)
  // unless player landed on a reroll tile, the current player changes to the opponent
  if (!REROLL_COORDS.some(coord => sameCoord(coord, target))) state.currentPlayer = state.currentPlayer === 'host' ? 'guest' : 'host'
  // roll dice again
  state.currentRoll = rollDice()
  // return reference to same game state
  return state
}

/**
 * Switches current player without performing move.
 */
export function pass(state: GameState): GameState {
  // switch players
  state.currentPlayer = state.currentPlayer === 'host' ? 'guest' : 'host'
  // roll dice again
  state.currentRoll = rollDice()
  // return reference to same game state
  return state
}