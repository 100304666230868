import * as auth from './auth'
import supabase from './supabase'
import { Game } from './types'

export async function getGames({ playerId }: { playerId: string }) {
  return supabase
    .from('game')
    .select(`
      id,
      host,
      guest,
      host_name:player!game_host_fkey(username),
      guest_name:player!game_guest_fkey(username),
      created_at,
      updated_at,
      state,
      winner,
      code
    `)
    .or(`host.eq.${playerId},guest.eq.${playerId}`)
    .then(res => {
      if (res.error) throw res.error
      else return res.data.map(g => ({
        ...g,
        host_name: g.host_name?.username,
        guest_name: g.guest_name?.username
      }))
    })
}

export async function getGame({ gameId }: { gameId: string }) {
  return supabase
    .from('game')
    .select(`
      id,
      host,
      guest,
      host_name:player!game_host_fkey(username),
      guest_name:player!game_guest_fkey(username),
      created_at,
      updated_at,
      state,
      winner,
      code
    `)
    .eq('id', gameId)
    .single()
    .then(res => {
      if (res.error) throw res.error
      else return {
        ...res.data,
        host_name: res.data.host_name?.username,
        guest_name: res.data.guest_name?.username
      }
    })
}

export async function updateGame({ gameId, state, winner }: { gameId: string; state: string; winner?: string; }) {
  return supabase
    .from('game')
    .update({ state, updated_at: new Date().toISOString(), winner })
    .eq('id', gameId)
    .select()
    .single()
    .then(res => {
      if (res.error) throw res.error
      else return res.data
    })
}

export async function subscribeToGameUpdates({ onUpdate }: { onUpdate: (game: Game) => void; }) {
  supabase
    .channel('game-updates')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public'
      },
      payload => onUpdate(payload.new as Game)
    )
    .subscribe()
}

export async function unsubscribeFromGameUpdates() {
  supabase.channel('game-updates').unsubscribe()
  supabase.removeChannel(supabase.channel('game-updates'))
}

async function getLastGameCode() {
  return supabase
    .from('game')
    .select('code')
    .is('guest', null)
    .order('code', { ascending: true })
    .limit(1)
    .then(res => {
      if (res.error) throw res.error
      else return res.data.at(0)?.code
    })
}

function getNextCodeFromLastCode(lastCode: string | undefined) {
  if (lastCode === undefined) return 'AAA'
  else return lastCode
    .split('')
    .reverse()
    .reduce((prev, cur) => {
      let carry = false
      let charCode = cur.charCodeAt(0) + (prev.carry ? 1 : 0)
      if (charCode > 'Z'.charCodeAt(0)) {
        charCode = 'A'.charCodeAt(0)
        carry = true
      }
      return {
        carry,
        code: String.fromCharCode(charCode) + prev.code
      }
    }, { carry: true, code: '' })
    .code
}

async function getNextGameCode() {
  const lastGameCode = await getLastGameCode()
  return getNextCodeFromLastCode(lastGameCode)
}

export async function hostGame() {
  const code = await getNextGameCode().catch(_ => {
    throw new Error('Error getting next game code')
  })
  return supabase
    .from('game')
    .insert({ code })
    .select()
    .single()
    .then(res => {
      if (res.error) throw res.error
      return res.data
    })
}

export async function joinGame({ playerId, code }: { playerId?: string; code: string; }) {
  const userId = playerId ?? (await auth.getUser()).id
  return supabase
    .from('game')
    .update({ guest: userId })
    .eq('code', code)
    .is('guest', null)
    .neq('host', userId)
    .select()
    .then(res => {
      if (res.error) throw res.error
      else if (!res.data.length) throw new Error(`Open game with code ${code} not found`)
      else return res.data[0]
    })
}