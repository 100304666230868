import { useState } from 'react'
import { Alert, Button, StyleSheet, Text, TextInput, View } from 'react-native'
import colors from '../js/colors'
import { signup } from '../js/supabase'
import { NavigationProp } from '@react-navigation/native'

export default function Signup({ email: lastEmail, password: lastPassword, navigation }: { navigation: NavigationProp<any>; email?: string; password?: string; }) {
  const [email, _setEmail] = useState(lastEmail ?? '')
  const [password, _setPassword] = useState(lastPassword ?? '')
  const [signingUp, setSigningUp] = useState(false)
  const [message, setMessage] = useState('')

  function setEmail(text: string) {
    setMessage('')
    _setEmail(text)
  }

  function setPassword(text: string) {
    setMessage('')
    _setPassword(text)
  }

  function onSignupPressed() {
    console.log('🕹️ signing up...')
    setSigningUp(true)
    signup({ email: email.trim(), password })
      .then(res => {
        console.log('🕹️ signed up as', res.user.email ?? res.user.id)
        navigation.navigate('Home')
      })
      .catch(err => {
        Alert.alert('Signup Error', 'Error signing up')
        console.error('🕹️', err)
        if (err instanceof Error) setMessage(`${err.message} 😭`)
        else setMessage('Something went wrong 😭')
      })
      .finally(() => setSigningUp(false))
  }

  function onLoginPressed() {
    navigation.navigate('Login', { email, password })
  }

  navigation.addListener('focus', () => setMessage(''))

  return (
    <View style={styles.centerContainer}>
      <View style={styles.fixedWidthContainer}>
        <Text style={styles.headerText}>Signup</Text>
        <View style={styles.inputsContainer}>
          <TextInput
            style={{ ...(email ? styles.text : styles.textMuted ), ...styles.textInput }}
            placeholder='Email'
            value={email}
            onChangeText={setEmail}
            textContentType='emailAddress'
            autoCapitalize='none'
          />
          <TextInput
            style={{ ...(password ? styles.text : styles.textMuted ), ...styles.textInput }}
            secureTextEntry={true}
            placeholder='Password'
            value={password}
            onChangeText={setPassword}
            autoCapitalize='none'
          />
        </View>
        { Boolean(message) && <Text style={{ ...styles.textMuted, ...styles.messageText }}>{message}</Text> }
        <Button
          title='Signup'
          disabled={signingUp}
          onPress={onSignupPressed}
        />
        <Button
          title='Login'
          disabled={signingUp}
          onPress={onLoginPressed}
          color='#aaa'
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center'
  },
  fixedWidthContainer: {
    width: 240,
    gap: 10
  },
  inputsContainer: {
    gap: 10,
    marginVertical: 10
  },
  text: {
    color: colors.foreground
  },
  textMuted: {
    color: colors.foregroundMuted
  },
  textInput: {
    backgroundColor: colors.backgroundStrong,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
    textAlign: 'center'
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.foregroundStrong,
    textAlign: 'center'
  },
  messageText: {
    textAlign: 'center',
    marginBottom: 10
  }
})