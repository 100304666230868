import { NavigationProp } from '@react-navigation/native'
import { useEffect } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import colors from '../js/colors'
import { refresh } from '../js/supabase'

export default function Refresh({ navigation }: { navigation: NavigationProp<any>; }) {
  useEffect(() => {
    console.log('🕹️ refreshing session...')
    refresh()
      .then(res => {
        if (!res.user || !res.session) {
          console.log('🕹️ session user not defined')
          navigation.navigate('Login')
        }
        else {
          console.log('🕹️ session refreshed for', res.user.email ?? res.user.id)
          navigation.navigate('Home')
        }
      })
      .catch(() => {
        console.log('🕹️ session could not be refreshed')
        navigation.navigate('Login')
      })
  }, [])

  return (
    <View style={styles.centerContainer}>
      <Text style={styles.text}>
        Refreshing session...
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontStyle: 'italic',
    color: colors.foreground
  },
})