import { useState } from 'react'
import { Pressable, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import colors from '../js/colors'
import { CurrentPlayer, END_COORDS, GameBoardCoord, GameState, START_COORDS, isCoordReroll, isPlayerOnTile, isTileOccupied, move, sameCoord, tokensOnTile } from '../js/game'

type TileProps = {
  player?: CurrentPlayer;
  coord: GameBoardCoord;
  value: number | string;
  selected: boolean;
  onPress: () => void;
}

type BoardProps = {
  userName: string;
  hostName: string;
  guestName: string;
  state: GameState;
  setState: React.Dispatch<React.SetStateAction<GameState>>;
  isGameOver: boolean;
}

function xyToCoord(x: number, y: number): GameBoardCoord {
  // @ts-ignore
  return { x, y }
}

function Tile({ player, coord, value, selected, onPress }: TileProps) {
  let tileStyle: ViewStyle = styles.tile
  const isStartOrEndTile = [START_COORDS, END_COORDS].flatMap(o => Object.values(o)).some(c => sameCoord(c, coord))
  if (isStartOrEndTile) tileStyle = styles.emptyTileSpace
  else if (isCoordReroll(coord)) tileStyle = { ...tileStyle, ...styles.rerollTile }
  if (selected) tileStyle = { ...tileStyle, ...styles.selectedTile }

  let textStyle: TextStyle = styles.tokenText
  if (isTileOccupied(value.toString() as any)) {
    textStyle = { ...textStyle, ...styles.tokenTextOccupied }
    if (player === 'host') tileStyle = { ...tileStyle, borderColor: colors.tokenHost }
    if (player === 'guest') tileStyle = { ...tileStyle, borderColor: colors.tokenGuest }
    if (selected) textStyle = { ...textStyle, ...styles.tokenTextHighlighted }
  }
  if (player === 'host') textStyle = { ...textStyle, ...styles.tokenTextHost }
  else if (player === 'guest') textStyle = { ...textStyle, ...styles.tokenTextGuest }

  return (
    <Pressable onPress={onPress}>
      <View style={tileStyle}>
        <Text style={textStyle}>{value}</Text>
      </View>
    </Pressable>
  )
}

export default function Board({ userName, hostName, guestName, state, setState, isGameOver }: BoardProps) {
  const [selectedTile, setSelectedTile] = useState<GameBoardCoord>()

  const currentPlayerName = state.currentPlayer === 'host'
    ? hostName
    : guestName

  function onTilePressed(touchedTile: GameBoardCoord) {
    // ignore input under these circumstances:
    if (isGameOver) return
    if (currentPlayerName !== userName) return
    if (state.currentRoll === 0) return
    // if a tile was already selected
    if (selectedTile) {
      // if touching the tile that was selected, deselect it
      if (sameCoord(selectedTile, touchedTile)) setSelectedTile(undefined)
      // if touching a different tile than the one selected, try moving!
      else {
        try {
          setState(move(state, selectedTile, touchedTile))
          console.log(`🕹️ Moved from [${selectedTile.x}, ${selectedTile.y}] to [${touchedTile.x}, ${touchedTile.y}]`)
        } catch (_) {}
      }
    }
    // select the tile if it is occupied by the current player and is not an end tile
    else if (
      isPlayerOnTile(state.board[touchedTile.y][touchedTile.x], state.currentPlayer)
      && !Object.values(END_COORDS).some(coord => sameCoord(coord, touchedTile))
    ) setSelectedTile(touchedTile)
  }

  return (
    <View style={styles.container}>
      {
        state.board.map((row, i) => (
          <View key={i} style={styles.row}>
            {
              row.map((col, j) => {
                const coord = xyToCoord(j, i)
                const { count: tokens, player } = tokensOnTile(col)
                return (
                  <Tile
                    coord={coord}
                    value={tokens}
                    player={player}
                    selected={selectedTile && sameCoord(selectedTile, coord)}
                    onPress={() => onTilePressed(coord)}
                    key={(i * row.length) + j}
                  />
                )
              })
            }
          </View>
        ))
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    rowGap: 10
  },
  row: {
    flexDirection: 'row',
    columnGap: 10
  },
  emptyTileSpace: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 25
  },
  tile: {
    width: 50,
    height: 50,
    backgroundColor: colors.tileBackground,
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 4,
    borderColor: colors.tileOutline,
    borderWidth: 1
  },
  rerollTile: {
    borderRadius: 25
  },
  selectedTile: {
    backgroundColor: colors.tileBackgroundHighlight
  },
  tokenText: {
    color: colors.tokenEmpty,
    margin: 'auto',
    borderRadius: 15,
    paddingTop: 5,
    width: 30,
    height: 30,
    textAlign: 'center'
  },
  tokenTextOccupied: {
    backgroundColor: colors.tokenBackground,
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: colors.tokenOutline
  },
  tokenTextHost: {
    color: colors.tokenHost
  },
  tokenTextGuest: {
    color: colors.tokenGuest
  },
  tokenTextHighlighted: {
    borderColor: colors.tokenOutlineHighlighted
  }
})