import { NavigationProp } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { Alert, ScrollView, StyleSheet, Text, View } from 'react-native'
import AppBar from '../components/AppBar'
import GameList from '../components/GameList'
import { NewGame } from '../components/NewGame'
import colors from '../js/colors'
import { getGames, getUserWithOptionalUsername, getUserWithUsername, hostGame, joinGame, logout, subscribeToGameUpdates, unsubscribeFromGameUpdates } from '../js/supabase'
import { GameWithPlayers, UserWithUsername } from '../js/supabase/types'

function gameSorter(a: GameWithPlayers, b: GameWithPlayers) {
  return (b.updated_at ?? b.created_at).localeCompare(a.updated_at ?? a.created_at)
}

export default function Home({ navigation }: { navigation: NavigationProp<any> }) {
  const [user, setUser] = useState<UserWithUsername>()

  const [games, setGames] = useState<GameWithPlayers[] | undefined>(undefined)

  const pendingInvite = games?.filter(g => g.host === user?.id).some(g => !g.guest) ?? false

  function fetchGames({ playerId }: { playerId: string }) {
    console.log('🕹️ fetching games')
    getGames({ playerId })
      .then(data => {
        console.log(`🕹️ retrieved ${data.length} game(s)`)
        setGames(data.sort(gameSorter))
      })
      .catch(err => {
        Alert.alert('Fetch Games Error', 'Error fetching games')
        console.error('🕹️', err)
      })
  }

  function onHostGamePressed() {
    if (pendingInvite) return Alert.alert('Waiting for Opponent', 'Cannot host new game while waiting for opponent')

    console.log('🕹️ hosting new game...')
    hostGame()
      .then(hostedGame => {
        console.log('🕹️ new game created')
        const newGame: GameWithPlayers = { ...hostedGame, host_name: user.username }
        setGames(games ? [newGame].concat(games) : [newGame])
      })
      .catch(err => {
        Alert.alert('Create Game Error', 'Error hosting new game')
        console.error('🕹️', err)
      })
  }

  function onJoinGamePressed(code: string) {
    console.log(`🕹️ joining game ${code}...`)
    joinGame({ playerId: user.id, code: code.toUpperCase() })
      .then(async ({ id }) => {
        Alert.alert('Successfully joined game')
        console.log('🕹️ successfully joined game', id)
        navigation.navigate('Game', { gameId: id })
      })
      .catch(err => {
        Alert.alert('Join Game Error', `Error joing game ${code}`)
        console.error('🕹️', err)
      })
  }

  function onLogoutPressed() {
    console.log('🕹️ logging out...')
    logout()
      .then(() => {
        console.log(`🕹️ ${user.email} logged out`)
        navigation.navigate('Login')
      })
      .catch(err => {
        Alert.alert('Logout Error', 'Error logging out')
        console.error('🕹️', err)
      })
  }

  function onGamePressed(game: GameWithPlayers) {
    if (game.guest) playGame(game.id)
  }

  function playGame(gameId: string) {
    navigation.navigate('Game', { gameId })
  }

  navigation.addListener('focus', () => {
    setUser(undefined)
    getUserWithOptionalUsername()
      .then(u => {
        if (u.username) setUser(u as UserWithUsername)
        else navigation.navigate('Username')
      })
      .catch(err => {
        console.error('🕹️', err)
        navigation.navigate('Login')
      })
  })

  useEffect(() => {
    if (!user) return
    // fetch games
    fetchGames({ playerId: user.id })
    // listen for changes to the games
    subscribeToGameUpdates({
      onUpdate: _ => fetchGames({ playerId: user.id })
    })
      .then(() => console.log('🕹️ listening for game updates'))
      .catch(err => {
        Alert.alert('Subscribe Error', 'Error subscribing to  game updates')
        console.error('🕹️', err)
      })
    // cleanup function
    return () => {
      unsubscribeFromGameUpdates()
        .then(() => console.log('🕹️ no longer listening for game updates from home screen'))
        .catch(err => {
          Alert.alert('Unsubscribe Error', 'Error unsubscribing from game updates')
          console.error('🕹️', err)
        })
    }
  }, [user])

  if (user) return (
    <View style={styles.centerContainer} >
      <AppBar
        key={user.username}
        username={user.username}
        onUsernamePressed={() => navigation.navigate('Username')}
        onLogoutPressed={onLogoutPressed}
      />
      <ScrollView style={styles.gamesContainer}>
        <View style={styles.section}>
          <Text style={styles.headerText}>Start a New Game</Text>
          <NewGame
            hostGame={onHostGamePressed}
            joinGame={onJoinGamePressed}
            canHost={!pendingInvite}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.headerText}>Games</Text>
          <GameList
            games={games}
            userId={user.id}
            onGamePressed={onGamePressed}
          />
        </View>
      </ScrollView>
    </View>
  )
  else return (
    <View style={{ ...styles.centerContainer, justifyContent: 'center' }} >
      <Text style={{ color: colors.foreground }}>Loading...</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    backgroundColor: colors.background,
    alignItems: 'center'
  },
  gamesContainer: {
    gap: 10,
    paddingHorizontal: 10,
    paddingBottom: 10,
    width: '100%'
  },
  section: {
    backgroundColor: colors.backgroundStrong,
    padding: 15,
    gap: 10,
    borderRadius: 4,
    marginTop: 10
  },
  headerText: {
    color: colors.foregroundStrong,
    fontWeight: 'bold',
    fontSize: 20
  }
})