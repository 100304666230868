export function getFormattedDatetime(datetime: string, long = false) {
  let result: string

  // ensure datetime format has 2-digit numbers
  let input = datetime
  const regex = /T\d:/.exec(datetime)
  if (regex) {
    const first = datetime.slice(0, regex.index + 1)
    const last = datetime.slice(regex.index + 1)
    input = `${first}0${last}`
  }

  // create the date object & options
  const date = new Date(input)
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...(long
      ? {
          hour: "numeric",
          minute: "numeric",
        }
      : undefined),
  }

  // return the formatted value
  try {
    result = new Intl.DateTimeFormat(undefined, options).format(date)
  } catch (_error) {
    result = datetime
    console.error(`Could not format datetime value: "${datetime}"`)
  }
  return result
}